import SeoPageBuilderPage from 'src/components/modules/page/SeoPageBuilderPage'
import getSeoPageBuilderPageProps from 'src/utils/pages/getSeoPageBuilderPageProps'
import {GetServerSideProps} from 'next'

export default function Page(props: any) {
  return <SeoPageBuilderPage {...props} />
}

export const getServerSideProps: GetServerSideProps = async (context: any) => {
  return await getSeoPageBuilderPageProps(context)
}
