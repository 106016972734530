import {PageProps} from 'src/types/page'
import dynamic from 'next/dynamic'

const PageExperienceTemplate = dynamic(
  () => import('src/components/modules/page/template/PageExperienceTemplate'),
  {ssr: true},
)

const PageDefaultTemplate = dynamic(
  () => import('src/components/modules/page/template/PageDefaultTemplate'),
  {ssr: true},
)

const PageCompleteTemplate = dynamic(
  () => import('src/components/modules/page/template/PageCompleteTemplate'),
  {ssr: true},
)

const PageAgencyTemplate = dynamic(
  () => import('src/components/modules/page/template/PageAgencyTemplate'),
  {ssr: true},
)

export default function SeoPageBuilderPage(props: {page: PageProps}) {
  // Use key {page.id} so react dont re-render the same component on page and cause issue
  //click and make it open the map by default and use other useState default......

  let InnerComponent = null
  switch (props.page.template) {
    case 'experience':
      InnerComponent = PageExperienceTemplate
      break
    case 'complete':
      InnerComponent = PageCompleteTemplate
      break
    case 'agency':
      InnerComponent = PageAgencyTemplate
      break
    case 'default':
    default:
      InnerComponent = PageDefaultTemplate
  }

  return (
    <div key={props.page.id}>
      <InnerComponent page={props.page} />
    </div>
  )
}
